<template>
  <div class="retailers__filter">
    <div v-if="brandOptions.length > 2" class="brand-filter">
      <label class="input-label" for="brandOption">{{getTranslation('brandFormLabel')}}</label>
      <select2 :options="brandOptions" v-model="selectedBrands" id="brandOption" @input="setBrands">
        <option value="-1">{{getTranslation('allBrands')}}</option>
      </select2>
    </div>

    <div v-if="productOptions.length > 2" v-bind:class="{ last: onlineOnly }"
         class="category-filter">
      <label class="input-label" for="productOption">{{getTranslation('productFormLabel')}}</label>
      <select2 :options="productOptions" v-model="selectedProducts" id="productOption" @input="setProducts">
        <option value="-1">{{getTranslation('allProducts')}}</option>
      </select2>
    </div>

    <div class="online-filter">
      <label class="checkbox-label">
        <input type="checkbox" value="true" v-model="onlineOnly" @change="setOnline"> {{getTranslation('online')}}
        <span class="checkbox"></span>
      </label>
    </div>

    <div v-show="!onlineOnly" class="search-filter">
      <label for="address" class="input-label">{{getTranslation('addressLabel')}}</label>
      <input type="text" id="address" v-model="address" v-bind:placeholder="getTranslation('addressPlaceholder')"
             autocomplete="off">
    </div>

    <div v-show="!onlineOnly" class="position-filter">
      <label v-on:click="goToPosition" class="link-label">
        <i class="far fa-crosshairs"></i>
        {{getTranslation('positionLabel')}}
      </label>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import select2 from '../components/select2'
import { language } from '../mixins/language.js'

export default {
  name: 'KGKFilter',
  mixins: [language],
  props: {
    brands: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      address: '',
      selectedProducts: '-1',
      selectedBrands: '-1',
      onlineOnly: false,
      storeOrShop: 'store'
    }
  },
  computed: {
    productOptions () {
      let values = [{
        'id': -1,
        'text': this.words.allProducts,
        'disabled': false
      }]

      this.brands.forEach(brand => {
        brand.terms.forEach(termSlug => {
          values.push({
            id: termSlug,
            text: brand.term_labels[termSlug],
            disabled: !(this.selectedBrands === '-1' || brand.slug === this.selectedBrands),
            selected: termSlug === this.selectedProducts
          })
        })
      }
      )

      return values
    },
    brandOptions () {
      let values = [{
        'id': -1,
        'text': this.words.allBrands,
        'disabled': false
      }]
      this.brands.forEach(brand => {
        values.push({
          'id': brand.slug,
          'text': brand.title,
          'selected': brand.slug === this.selectedBrands
        })
      })

      return values
    }
  },
  methods: {
    setupAutocomplete () {
      const $locators = $('#retailers #address')
      const autocompletes = []

      if (!$locators.length) {
        return
      }
      /* eslint-disable */

        // From http://stackoverflow.com/a/11703018/827642
        function pacSelectFirst(input) {
          // store the original event binding function
          let _addEventListener = (input.addEventListener) ? input.addEventListener:input.attachEvent

          function addEventListenerWrapper(type, listener) {
            // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected,
            // and then trigger the original listener.
            if (type==="keydown") {
              let orig_listener = listener;
              listener = function (event) {
                let suggestion_selected = $(".pac-item-selected").length > 0;
                if (event.which===13 && !suggestion_selected) {
                  let simulated_downarrow = $.Event("keydown", {
                    keyCode: 40,
                    which: 40
                  });
                  orig_listener.apply(input, [simulated_downarrow]);
                }
                orig_listener.apply(input, [event]);
              };
            }
            _addEventListener.apply(input, [type, listener]);
          }

          input.addEventListener = addEventListenerWrapper;
          input.attachEvent = addEventListenerWrapper;
          autocompletes.push(new google.maps.places.Autocomplete(input, {componentRestrictions: {country: 'se'}}));
        }

        /* eslint-enable */

      $locators.each(function () {
        pacSelectFirst(this)
      })

      autocompletes.forEach((ac) => {
        google.maps.event.addListener(ac, 'place_changed', () => {
          this.$emit('addresssearch', ac.getPlace())
        })
      })
    },
    goToPosition: function () {
      this.$emit('gotoposition')
    },
    getAddressCoordinates () {
      return $.ajax({
        url: window.kgk_api_endpoint + 'coordinates',
        data: {
          address: this.address
        },
        dataType: 'json',
        method: 'GET'
      })
    },
    panToAddress: function () {
      this.$emit('searching', true)

      $.when(this.getAddressCoordinates()).done((response) => {
        if (response.coordinates) {
          this.$emit('addresssearch', response.coordinates)
        }
        this.$emit('searching', false)
      })
    },
    setOnline: function () {
      if (this.onlineOnly) {
        this.address = ''
      }
      this.$emit('addresssearch', {})
      this.$emit('onlineswitched', this.onlineOnly)
    },
    setStoreOrShop: function () {
      if (this.storeOrShop === 'shop') {
        this.onlineOnly = false
        this.$emit('onlineswitched', this.onlineOnly)
      }

      this.$emit('storeshopswitched', this.storeOrShop)
    },
    setBrands: function () {
      this.$emit('brandswitched', this.selectedBrands)
    },
    setProducts: function () {
      this.$emit('productswitched', this.selectedProducts)
    }
  },
  mounted: function () {
    this.$nextTick(() => {
      this.setupAutocomplete()
    })
  },
  components: {
    select2
  }
}
</script>
