<template>
  <div class="retailers-map">
    <div id="retailers__gm" v-show="mapView"></div>

    <KGKList v-bind:filtred-retailers="filtredListRetailers"
             v-bind:brands="brands"
             v-bind:selected-retailer="selectedRetailer"
             @listitemclicked="onListItemSelected"
             ref="list"></KGKList>
  </div>
</template>

<script>
import $ from 'jquery'
import { language } from '../mixins/language.js'
import KGKList from './list'
import MarkerClusterer from '@google/markerclusterer'
import debounce from 'debounce'

export default {
  name: 'KGKMap',
  mixins: [language],
  props: {
    filtredMapRetailers: Array,
    filtredListRetailers: Array,
    brands: Array,
    selectedRetailer: Object,
    mapCoordinates: Object,
    mapView: Boolean
  },
  data: function () {
    return {
      pin: false,
      selectedPin: false,
      markers: [],
      markerCluster: [],
      map: '',
      bounds: '',
      center: '',
      traveling: false
    }
  },
  methods: {
    onListItemSelected (data) {
      this.$emit('listitemclicked', data)
    },
    getOptimalZoomOut (latLng, currentZoom) {
      if (this.willAnimatePanTo(latLng, currentZoom - 1)) {
        return currentZoom - 1
      } else if (this.willAnimatePanTo(latLng, currentZoom - 2)) {
        return currentZoom - 2
      }
      return currentZoom - 3
    },
    project (latLng) {
      let TILE_SIZE = 256
      let siny = Math.sin(latLng.lat() * Math.PI / 180)
      siny = Math.min(Math.max(siny, -0.9999), 0.9999)

      return new google.maps.Point(
        TILE_SIZE * (0.5 + latLng.lng() / 360),
        TILE_SIZE * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI))
      )
    },
    getPixel (latLng, zoom) {
      let scale = 1 << zoom
      let worldCoordinate = this.project(latLng)

      return new google.maps.Point(
        Math.floor(worldCoordinate.x * scale),
        Math.floor(worldCoordinate.y * scale)
      )
    },
    getMapDimenInPixels () {
      let zoom = this.map.getZoom()
      let bounds = this.map.getBounds()

      let southWestPixel = this.getPixel(bounds.getSouthWest(), zoom)
      let northEastPixel = this.getPixel(bounds.getNorthEast(), zoom)

      return {
        width: Math.abs(southWestPixel.x - northEastPixel.x),
        height: Math.abs(southWestPixel.y - northEastPixel.y)
      }
    },
    willAnimatePanTo (destLatLng, optionalZoomLevel = this.map.getZoom()) {
      let dimen = this.getMapDimenInPixels()
      let mapCenter = this.map.getCenter()
      // optionalZoomLevel = optionalZoomLevel ? optionalZoomLevel : this.map.getZoom()
      let destPixel = this.getPixel(destLatLng, optionalZoomLevel)
      let mapPixel = this.getPixel(mapCenter, optionalZoomLevel)
      let diffX = Math.abs(destPixel.x - mapPixel.x)
      let diffY = Math.abs(destPixel.y - mapPixel.y)

      return diffX < dimen.width && diffY < dimen.height
    },
    smoothlyAnimatePanToWorkarround (destLatLng) {
      let self = this
      let initialZoom = this.map.getZoom()
      let listener

      function zoomIn () {
        if (self.map.getZoom() < initialZoom) {
          self.map.setZoom(Math.min(self.map.getZoom() + 3, initialZoom))
        } else {
          google.maps.event.removeListener(listener)
          self.traveling = false
        }
      }

      function zoomOut () {
        if (self.willAnimatePanTo(destLatLng)) {
          google.maps.event.removeListener(listener)
          listener = google.maps.event.addListener(self.map, 'idle', zoomIn)
          self.map.panTo(destLatLng)
        } else {
          self.traveling = false
          self.map.setZoom(self.getOptimalZoomOut(destLatLng, self.map.getZoom()))
        }
      }

      self.map.setZoom(self.getOptimalZoomOut(destLatLng, initialZoom))
      listener = google.maps.event.addListener(self.map, 'idle', zoomOut)
    },
    smoothlyAnimatePanTo (destLatLng) {
      if (this.willAnimatePanTo(destLatLng)) {
        this.map.panTo(destLatLng)

        google.maps.event.addListenerOnce(this.map, 'idle', () => {
          if (this.map.getZoom() < 10) {
            this.map.setZoom(10)
          }
        })
      } else {
        this.traveling = true
        this.smoothlyAnimatePanToWorkarround(destLatLng)
      }
    },
    getCurrentPosition: function () {
      this.$emit('searching', true)

      navigator.geolocation.getCurrentPosition((pos) => {
        const crd = pos.coords
        this.center = new google.maps.LatLng(crd.latitude, crd.longitude)
        this.smoothlyAnimatePanTo(this.center)
        this.$emit('searching', false)
      }, (err) => {
        console.log(err)
        this.$emit('searching', false)
        alert(this.words.getPositionError)
      })
    },
    addMarkers: function () {
      this.filtredMapRetailers.forEach((retailer, index) => {
        const position = new google.maps.LatLng(parseFloat(retailer.coordinates.lat), parseFloat(retailer.coordinates.lng))
        let marker = new google.maps.Marker({
          position,
          title: retailer.web_namn,
          retailerID: parseInt(retailer.post_id, 10),
          icon: this.pin
        })

        marker.addListener('click', () => {
          let nextMarker = null

          if (!this.selectedRetailer || parseInt(this.selectedRetailer.post_id, 10) !== marker.retailerID) {
            if (this.selectedRetailer) {
              this.markers.some((marker) => {
                if (marker.retailerID === parseInt(this.selectedRetailer.post_id, 10)) {
                  marker.setIcon(this.pin)
                  return true
                }
                return false
              })
            }
            marker.setIcon(this.selectedPin)
            nextMarker = marker

            this.center = {
              lat: parseFloat(marker.getPosition().lat()),
              lng: parseFloat(marker.getPosition().lng())
            }

            this.smoothlyAnimatePanTo(marker.getPosition())
          } else {
            marker.setIcon(this.pin)
          }
          this.$emit('markerselected', nextMarker)

          if (nextMarker) {
            this.maybeScrollToMap()
          }
        })

        this.markers.push(marker)
        // Make map fit all markers.
        this.map.fitBounds(this.bounds.extend(position))
      })

      this.markerCluster = new MarkerClusterer(this.map, this.markers, {
        imagePath: window.kgk_client_base_url + 'vue/retailers/images/m',
        zoomOnClick: true,
        gridSize: 50
      })
      this.$emit('markersadded', this.markers)
    },
    maybeScrollToMap () {
      if ($(window).width() <= 543) {
        $([document.documentElement, document.body]).animate({
          scrollTop: $('.retailers-map').offset().top
        }, 750)
      }
    },
    onGoogleLoaded () {
      this.bounds = new google.maps.LatLngBounds()
      this.center = this.filtredMapRetailers.length > 0 ? {
        lat: parseFloat(this.filtredMapRetailers[0].coordinates.lat),
        lng: parseFloat(this.filtredMapRetailers[0].coordinates.lng)
      } : {
        lat: 59.3405048, // Defaulting to our office
        lng: 18.0847522
      }

      this.map = new google.maps.Map(document.getElementById('retailers__gm'), {
        zoom: 12,
        center: this.center
      })

      this.map.addListener('bounds_changed',
        debounce(() => {
          this.bounds = this.map.getBounds()
          this.$emit('mapboundschanged', this.bounds)
        }
        , 500)
      )

      // Hide loader when finished loading gmap
      this.map.addListener('tilesloaded', () => {
        if (this.markers.length !== 0) {
          this.$emit('searching', false)
          this.$emit('loading', false)
        }
      })

      this.map.setClickableIcons(false)
      this.addMarkers()
    },
    repaintCluster () {
      if (this.traveling) {
        return
      }

      // Hide all markers who belongs to a hidden retailer.
      let visibleMarkers = this.markers.filter((marker) => {
        let match = []
        this.filtredMapRetailers.some((retailer) => {
          if (parseInt(retailer.post_id, 10) === marker.retailerID) {
            match.push(retailer)
          }
          return parseInt(retailer.post_id, 10) === marker.retailerID
        })
        return match.length !== 0
      })

      this.markerCluster.clearMarkers()
      this.markerCluster.addMarkers(visibleMarkers)
    }
  },
  watch: {
    selectedRetailer (next, prev) {
      if (prev) {
        this.markers.some((marker) => {
          if (marker.retailerID === parseInt(prev.id, 10)) {
            marker.setIcon(this.pin)
            return true
          }
          return false
        })
      }
      if (!prev || (next && prev && next.id !== prev.id)) {
        this.markers.some((marker) => {
          if (marker.retailerID === parseInt(next.id, 10)) {
            marker.setIcon(this.selectedPin)
            return true
          }
          return false
        })
      }
    },
    center () {
      this.$emit('mapcenter', this.center)
    },
    mapCoordinates (place) {
      if (typeof place !== 'undefined' && typeof place.geometry !== 'undefined') {
        let distancies = []

        this.markers.forEach((marker) => {
          distancies.push({
            marker,
            distance: google.maps.geometry.spherical.computeDistanceBetween(marker.position, place.geometry.location)
          })
        })

        if (distancies.length > 0) {
          const closestMarker = distancies.reduce((prev, current) => (prev.distance < current.distance) ? prev : current).marker
          this.smoothlyAnimatePanTo(closestMarker.position)
        }
      }
    },
    filtredMapRetailers: {
      handler () {
        if (this.markers.length === 0) {
          this.addMarkers()
        }
        debounce(() => {
          this.repaintCluster()
        }
        , 500)()
      },
      deep: true
    }
  },
  mounted () {
    this.pin = {
      path: 'M89.723,82.787a12.506,12.506,0,0,0,20.555,0C185.953-26.921,200-38.18,200-78.5a100,100,0,0,0-100-100A100,100,0,0,0,0-78.5C0-38.18,14.047-26.921,89.723,82.787ZM100-36.833A41.667,41.667,0,0,1,58.333-78.5,41.667,41.667,0,0,1,100-120.166,41.667,41.667,0,0,1,141.667-78.5,41.667,41.667,0,0,1,100-36.833Z',
      fillColor: window.kgk_client_icon_color,
      fillOpacity: 1,
      strokeWeight: 0,
      scale: 0.1,
      size: new google.maps.Size(200, 266),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(18, 18),
      scaledSize: new google.maps.Size(25, 25)
    }

    this.selectedPin = {
      path: 'M89.723,82.787a12.506,12.506,0,0,0,20.555,0C185.953-26.921,200-38.18,200-78.5a100,100,0,0,0-100-100A100,100,0,0,0,0-78.5C0-38.18,14.047-26.921,89.723,82.787ZM100-36.833A41.667,41.667,0,0,1,58.333-78.5,41.667,41.667,0,0,1,100-120.166,41.667,41.667,0,0,1,141.667-78.5,41.667,41.667,0,0,1,100-36.833Z',
      fillColor: '#389C40',
      fillOpacity: 1,
      strokeWeight: 0,
      scale: 0.1,
      size: new google.maps.Size(200, 266),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(18, 18),
      scaledSize: new google.maps.Size(25, 25)
    }

    const interval = setInterval(() => {
      if (typeof google !== 'undefined') {
        this.onGoogleLoaded()
        clearInterval(interval)
      }
    }, 200)
  },
  components: {
    KGKList
  }
}
</script>
