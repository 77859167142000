<template>
  <div id="retailers">
    <div class="retailers__header">
      <h1>{{getTranslation('title')}}</h1>
    </div>
    <div class="retailers__aside">
      <KGKFilter v-bind:brands="brands"
                 @onlineswitched="toggleView"
                 @searching="setSearching"
                 @addresssearch="setMapCoordinates"
                 @brandswitched="setBrands"
                 @gotoposition="goToPosition"
                 @productswitched="setProducts" ref="filter"></KGKFilter>
    </div>
    <div class="retailers__body">
      <div class="retailers-tabs" v-show="mapView">
        <div class="tab" v-on:click="switchTab(false)" v-bind:class="{ active : !filter.activeMapTab }">{{getTranslation('list')}}</div>
        <div class="tab" v-on:click="switchTab(true)" v-bind:class="{ active : filter.activeMapTab }">{{getTranslation('map')}}</div>
      </div>

      <div class="retailers-wrapper">
        <KGKMap v-bind:filtred-map-retailers="filtredMapRetailers"
                v-bind:filtred-list-retailers="filtredListRetailers"
                v-bind:brands="brands"
                v-bind:map-coordinates="mapCoordinates"
                v-bind:selected-retailer="selectedRetailer"
                v-bind:map-view="filter.activeMapTab"
                @searching="setSearching"
                @loading="setLoading"
                @mapboundschanged="setMapBound"
                @markersadded="setMarkers"
                @mapcenter="setMapCenter"
                @markerselected="setSelectedRetailer"
                @listitemclicked="onListItemSelected"
                v-bind:class="{ active: mapView}" ref="gmap"></KGKMap>

        <KGKCards v-bind:class="{ active: !mapView}"
                  v-bind:filtred-retailers="onlineRetailers" ref="cards"></KGKCards>
      </div>
    </div>

    <div class="overlay-spinner" v-bind:class="[{hidden: !searching},{init: loading}]">
      <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import { language } from './mixins/language.js'
import KGKFilter from './components/filter.vue'
import KGKMap from './components/map.vue'
import KGKCards from './components/cards.vue'

export default {
  name: 'retailers',
  mixins: [language],
  data: function () {
    return {
      loading: true,
      searching: true,
      retailers: window.kgk_retailers || [],
      brands: window.kgk_brands || [],
      mapView: true,
      mapCenter: false,
      filtredMapRetailers: [],
      filtredListRetailers: [],
      onlineRetailers: [],
      markers: [],
      selectedRetailer: null,
      mapCoordinates: {},
      mapBounds: false,
      filter: {
        storeOrShop: 'store',
        brands: '-1',
        products: '-1',
        activeMapTab: true
      }
    }
  },
  watch: {
    mapBounds: function () {
      this.filtredListRetailers = this.filterRetailers(this.filter.activeMapTab)
    },
    filter: {
      handler () {
        this.filtredMapRetailers = this.filterRetailers(false)
        this.filtredListRetailers = this.filterRetailers(this.filter.activeMapTab)
      },
      deep: true
    }
  },
  methods: {
    switchTab (state) {
      this.filter.activeMapTab = state
      this.filtredListRetailers = this.filterRetailers(this.filter.activeMapTab)
    },
    filterRetailers (useBounds = false) {
      // Store or shop
      let filtred = this.retailers

      // Brand filter
      if (this.filter.brands !== '-1') {
        let brandFilter = []
        // Get all products connected to our brand
        this.brands.forEach((brand) => {
          if (brand.slug === this.filter.brands) {
            brand.terms.forEach(termSlug => {
              brandFilter.push(termSlug)
            })
          }
        })
        filtred = filtred.filter((retailer) => {
          return brandFilter.filter((bf) => {
            return retailer[bf] === 'Y'
          }).length > 0
        })
      }

      // Product filter
      if (this.filter.products !== '-1') {
        filtred = filtred.filter((retailer) => {
          return retailer[this.filter.products] === 'Y'
        })
      }
      // Map bound filter, if on tab "list" we ignore boundaries
      if (useBounds && this.mapBounds) {
        const markersInBounds = this.markers.filter((marker) => this.mapBounds.contains(marker.getPosition()))
        filtred = filtred.filter((retailer) => {
          return markersInBounds.filter((marker) => {
            return marker.retailerID === parseInt(retailer.post_id, 10)
          }).length > 0
        })
        filtred = this.sortRetailersByDistance(filtred)
      }

      return filtred
    },
    sortRetailersByDistance (retailers) {
      return retailers.sort((r1, r2) => {
        return this.getRetailerDistance(r1) - this.getRetailerDistance(r2)
      })
    },
    getRetailerDistance (retailer) {
      return google.maps.geometry.spherical.computeDistanceBetween(
        new google.maps.LatLng(retailer.coordinates.lat, retailer.coordinates.lng),
        new google.maps.LatLng(this.mapCenter.lat, this.mapCenter.lng)
      )
    },
    setSelectedRetailer (marker) {
      if (!marker) {
        this.selectedRetailer = null
      } else {
        this.selectedRetailer = this.filtredMapRetailers.filter((retailer) => {
          return parseInt(retailer.post_id, 10) === marker.retailerID
        })[0]
      }
    },
    setSearching (searching) {
      this.searching = searching
    },
    setLoading (loading) {
      this.loading = loading
    },
    toggleView (onlineOnly) {
      this.mapView = onlineOnly === false
    },
    setStoreShopFilter (storeOrShop) {
      this.filter.storeOrShop = storeOrShop
    },
    setBrands (brands) {
      this.filter.brands = brands
    },
    setProducts (products) {
      this.filter.products = products
    },
    setMapBound (bound) {
      this.mapBounds = bound
    },
    setMarkers (markers) {
      this.markers = markers
    },
    setMapCenter (center) {
      this.mapCenter = center
    },
    setMapCoordinates (place) {
      this.mapCoordinates = place
    },
    onListItemSelected (data) {
      if (!data.selected) {
        this.selectedRetailer = data.retailer
      } else {
        this.selectedRetailer = null
      }
    },
    goToPosition () {
      const child = this.$refs.gmap
      child.getCurrentPosition()
    },
    getRetailersFromApi () {
      return $.ajax({
        url: window.kgk_api_endpoint + 'retailers',
        data: {
          category_filter: window.kgk_filter_products,
          brands_filter: window.kgk_filter_brands
        },
        dataType: 'json',
        method: 'GET'
      })
    },
    getBrandsFromApi () {
      return $.ajax({
        url: window.kgk_api_endpoint + 'brands',
        data: {
          category_filter: window.kgk_filter_products,
          brands_filter: window.kgk_filter_brands
        },
        dataType: 'json',
        method: 'GET'
      })
    }
  },
  beforeMount: function () {
    if (this.retailers.length === 0) {
      // let retailersFromStorage = JSON.parse(localStorage.getItem('kgk_retailers_object'))
      let retailersFromStorage = false
      let now = Math.round(new Date().getTime() / 1000)
      // Use local storage for 24h old data maximum
      if (retailersFromStorage && (retailersFromStorage.date + (24 * 3600)) > now) {
        console.log('Using retailers from local storage')
        this.retailers = retailersFromStorage.retailers
        this.filtredMapRetailers = retailersFromStorage.retailers
        this.filtredListRetailers = retailersFromStorage.retailers

        this.onlineRetailers = this.retailers.filter((retailer) => {
          return (retailer.online_butik === 'Y') && retailer['web-shop'].length > 0 && retailer.företagstyp === 'Foretag'
        })
      } else {
        $.when(this.getRetailersFromApi()).done((response) => {
          this.retailers = response
          this.filtredMapRetailers = response
          this.filtredListRetailers = response
          localStorage.setItem('kgk_retailers_object', JSON.stringify({ 'date': Math.round(new Date().getTime() / 1000), 'retailers': response }))

          this.onlineRetailers = this.retailers.filter((retailer) => {
            return (retailer.online_butik === 'Y') && retailer['web-shop'].length > 0 && retailer.företagstyp === 'Foretag'
          })
        })
      }
    } else {
      this.onlineRetailers = this.retailers.filter((retailer) => {
        return (retailer.online_butik === 'Y') && retailer['web-shop'].length > 0 && retailer.företagstyp === 'Foretag'
      })
    }

    if (this.brands.length === 0) {
      let brandsFromStorage = JSON.parse(localStorage.getItem('kgk_brands_object'))
      let now = Math.round(new Date().getTime() / 1000)

      // Use local storage for 24h old data maximum
      if (brandsFromStorage && (brandsFromStorage.date + (24 * 3600)) > now) {
        console.log('Using brands from local storage')
        this.brands = brandsFromStorage.brands
      } else {
        $.when(this.getBrandsFromApi()).done((response) => {
          this.brands = response
          localStorage.setItem('kgk_brands_object', JSON.stringify({ 'date': Math.round(new Date().getTime() / 1000), 'brands': response }))
        })
      }
    }
    // Init
    this.filtredListRetailers = this.filterRetailers(this.filter.activeMapTab)
    this.filtredMapRetailers = this.filterRetailers(false)
  },
  components: {
    KGKFilter,
    KGKMap,
    KGKCards
  }
}
</script>

<style lang="scss">
  @import "assets/styles/app.scss";
</style>
