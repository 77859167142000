<template>
    <div class="retailer-cards">
        <div class="retailer-cards-wrapper">
            <a v-bind:href="retailer['web-shop']" target="_blank" class="retailer-card" v-for="retailer in filtredRetailers" v-bind:key="retailer.post_id">
                <img v-bind:src="retailer.logotype" v-bind:alt="retailer.web_namn" v-if="retailer.logo !== null">
                <img v-bind:src="getImagePlaceholder()" v-bind:alt="retailer.web_namn" v-if="retailer.logo === null">

                <span>{{retailer.web_namn}}</span>
            </a>
        </div>
    </div>
</template>

<script>
export default {
  name: 'KGKCards',
  props: {
    filtredRetailers: Array
  },
  methods: {
    getImagePlaceholder: function () {
      return window.kgk_client_base_url + 'vue/retailers/images/placeholder.png'
    }
  }
}
</script>
