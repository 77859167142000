export const language = {
  data () {
    return {
      words: window.kgk_language || []
    }
  },
  methods: {
    getTranslation (key) {
      let params = {}
      let str = key in this.words ? this.words[key] : false

      if (!str) {
        return ''
      }

      Object.entries(params).map(([pKey, pValue]) => {
        str = str.replace(`{{${pKey}}}`, pValue)
      })

      return str
    }
  }
}
