<template>
    <select>
        <slot disabled></slot>
    </select>
</template>

<script>
import $ from 'jquery'
import 'select2'

export default {
  name: 'select2',
  props: ['options', 'value'],
  template: '#select2-template',
  mounted: function () {
    let vm = this
    $(this.$el)
      // init select2
      .select2({ data: this.options, width: '100%' })
      .val(this.value)
      .trigger('change')
      // emit event on change.
      .on('change', function () {
        vm.$emit('input', this.value)
      })
  },
  watch: {
    value: function (value) {
      // update value
      $(this.$el)
        .val(value)
        .trigger('change')
    },
    options: function (options) {
      // update options
      $(this.$el).empty().select2({ data: options, width: '100%' })
    }
  },
  destroyed: function () {
    $(this.$el).off().select2('destroy')
  }
}
</script>
