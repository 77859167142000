<template>
    <div class="retailers__list">
        <KGKListItem v-for="retailer in filtredRetailers"
                     v-bind:key="retailer.post_id"
                     v-bind:retailer="retailer"
                     v-bind:brands="brands"
                     v-bind:selected="isSelectedRetailer(retailer)"
                     @click="setSelected">
        </KGKListItem>
    </div>
</template>

<script>
import KGKListItem from './list-item'

export default {
  name: 'KGKList',
  props: {
    brands: Array,
    filtredRetailers: Array,
    selectedRetailer: Object
  },
  components: {
    KGKListItem
  },
  methods: {
    setSelected (data) {
      this.$emit('listitemclicked', data)
    },
    isSelectedRetailer (retailer) {
      if (!this.selectedRetailer) {
        return false
      }

      return retailer.post_id === this.selectedRetailer.post_id
    }
  }
}
</script>
